import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
    print(url, data) {            
      return instance.post(url,data);
    },   
}

export default servicesAPI;
