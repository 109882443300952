<template>
  <b-row>
    <b-col lg="12">      
      <b-table class="mb-0"
            style="min-height:130px;"
            responsive
            head-variant="dark"
            :hover="true"
            :small="true"
            :fixed="true"
            :items="table.items"
            :fields="table.fields"                            
            :busy="table.isBusy"                     
            v-if="table.items.length || table.isBusy">   
          
        <template v-slot:table-colgroup="scope">
          <col
            v-for="field in scope.fields"                    
            :key="field.key"
            :style="{ width: field.width }"
          >
        </template> 

        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Cargando...</strong>
          </div>
        </template>           
        
        <template v-slot:cell(surcharge)="data">
          {{parseFloat(data.item.surcharge)}}%              
        </template>  

        <template v-slot:cell(amount)="data">
          <span v-if="currency">
            {{Intl.NumberFormat('es-AR',{style:'currency',currency: currency}).format(getAmountItem(data.item))}}              
          </span>          
        </template>  

        <template v-slot:cell(f_action)="data">
          <b-button variant="outline-dark" 
                    size="sm" 
                    @click="selectedItem(data.item)"
                    class="pull-right">
            <b-icon icon="chevron-double-right"></b-icon>
          </b-button>          
        </template>

      </b-table>
      <b-alert show variant="warning" v-else>
        No hay métodos de pago para mostrar
      </b-alert>
    </b-col>
  </b-row>
</template>
<script>  
  import serviceAPI from './../services'

  export default {
    props:{
      amount:{
        type: Number,
        required: true
      },        
      currency:{
        type: String,
        default: 'ARS',
      },              
    },
    data: () => {
      return {    
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', class:'text-left align-middle', width:"10%"},
            {key: 'name', label: 'Mètodo de Pago', class:'text-left align-middle', width:"30%"},            
            {key: 'surcharge', label: 'Recargo', class:'text-center align-middle', width:"25%"},            
            {key: 'amount', label: 'Importe Final', class:'text-right align-middle', width:"25%"},            
            {key: 'f_action', label:'', class:'text-right align-middle', width:"10%"},            
          ],
          isBusy: false,
        },                   
        arr: {
          methodPayment: [],          
        },        
      }
    },
    mounted(){      
      this.obtenerMethodPayment()           
    },    
    methods: {
      obtenerMethodPayment() {            
        this.arr.methodPayment = []            
        var result = serviceAPI.obtenerMetodosPagos()        
        result.then((response) => {
          var data = response.data
          this.table.items = data                              
        })  
      },  
      getAmountItem(item) {        
        let surcharge = parseFloat(item.surcharge)
        return this.amount * (1 + (surcharge/100))        
      },
      selectedItem(item) {
        this.$emit('setMethodPayment', item) 
      }
    }
  }
</script>